import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './Headernew.css';
import './Responsive.css';

import lupe from '../icons/LupeWeiss.png';

import { motion } from 'framer-motion';
import LinkData from './Links.json';
import LinksShop from './LinksShop.json';
import SearchDropdownMeili from './SearchDropdownMeili';
import { translateRoute } from 'utility/routing/common/routing';

function Headerprojects(props) {
  const [advancedsearchtoggle, setadvancedsearchtoggle] = useState(false);
  const [menu22, setmenu22] = useState([]);
  const [ProductCategoryArray, setProductCategoryArray] = useState({ data: [] });
  const [ProductCategoryData, setProductCategoryData] = useState({ data: [] });

  const [showResponsiveSublinks, setshowResponsiveSublinks] = useState(false);
  const [HeaderSubmenuLevelSlug, setHeaderSubmenuLevelSlug] = useState('');

  let [showChat, setShowChat] = useState(false);
  let [blackimg, setblackimg] = useState(false);
  let [sufuan, setsufuan] = useState(false);
  let [stickery, setstickery] = useState(false);
  let [responsivemenu, setresponsivemenu] = useState(false);
  let [headerdata, setheaderdata] = useState({ data: [] });
  let [headerdata2, setheaderdata2] = useState({ data: [] });
  let params = useParams();
  let param = useParams();
  let [smallmenutrigger, setsmallmenutrigger] = useState(false);
  let para = useParams();
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  let la;
  let arrla;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  useEffect(() => {
    setHeaderSubmenuLevelSlug('');
  }, [smallmenutrigger, responsivemenu]);

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/productcategories?locale=' +
        arrla1 +
        '&populate=HeaderImage&pagination[pageSize]=40&sort[0]=Priority:desc&filters[MainCategory][$eq]=true',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setProductCategoryData(json);
      });
  }, []);

  function cardhovershananigans(slugs) {
    console.log(slugs);
    let filterarr = { data: [] };
    filterarr = ProductCategoryData.data.filter((item) => item.attributes[slugs] == true);
    let obj = { data: filterarr };
    setProductCategoryArray(obj);
    console.log('quwenhqow');
  }

  let [linkarr, setlinkarr] = useState([]);
  useEffect(() => {
    decidethepaths();
  }, []);

  const location = useLocation();
  useEffect(() => {
    setresponsivemenu(false);
    if (document.getElementById('resp-menu-icon')) {
      if (document.getElementById('resp-menu-icon').classList.contains('rotation')) {
        document.getElementById('resp-menu-icon').classList.remove('rotation');
      }
    }

    if (document.querySelector('#Qrailings')) {
      document.querySelector('#Qrailings').style = 'pointer-events:none';
      setTimeout(() => {
        document.querySelector('#Qrailings').style = 'pointer-events:auto';
      }, 1200);
    }

    setsmallmenutrigger('');
    returnSubmenu();
  }, [location]);

  useEffect(() => {
    if (responsivemenu) {
      if (document.querySelector('.header-top-wrap')) {
        document.querySelector('.header-top-wrap');
        document.body.style = 'overflow-y:hidden';
        if (document.getElementById('resp-menu')) {
          document.getElementById('resp-menu').style = 'background-color:white';
        }
        setblackimg(true);
      }
    }
    if (!responsivemenu) {
      document.body.style = 'overflow-y:unset ';
      if (document.getElementById('resp-menu')) {
        document.getElementById('resp-menu').style = 'background-color:transparent';
      }
      setblackimg(false);
    }
  }, [responsivemenu]);

  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];
      if (para.count) {
        val = [para.count, 'en'];
        if (para.count.match('-')) {
          val = para.count.split('-');
        }
      }
      if (para.count == 'es') {
        val = ['es', 'es'];
      }
      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }

  function advancesearchfield(propsy) {
    if (propsy == false) {
      setadvancedsearchtoggle(propsy);
      setsufuan(propsy);
    }
  }

  let thefinalval = 'en_INTERNATIONAL';
  if (para.count) {
    thefinalval = para.count;
    if (para.count.match('-')) {
      thefinalval = thefinalval.replace('-', '_');
    }
  }

  function renderprojectflags() {
    let renderarr = [];
    if (props.translation) {
      props.dater.data[0].attributes.IndoorOutdoor == 'Indoor'
        ? renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[2])
        : props.dater.data[0].attributes.IndoorOutdoor == 'Outdoor'
          ? renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[3])
          : renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[4]);
      props.dater.data[0].attributes.Mount == 'Top mount'
        ? renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[5])
        : props.dater.data[0].attributes.Mount == 'Fasia mount'
          ? renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[6])
          : renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[7]);
      props.dater.data[0].attributes.balconies &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[8]);
      props.dater.data[0].attributes.stairs &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[9]);
      props.dater.data[0].attributes.glassrailing &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[10]);
      props.dater.data[0].attributes.frenchbalcony &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[11]);
      props.dater.data[0].attributes.handrail &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[12]);
      props.dater.data[0].attributes.posts &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[13]);
      props.dater.data[0].attributes.terrace &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[14]);
      props.dater.data[0].attributes.public &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[15]);
      props.dater.data[0].attributes.private &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[16]);
      props.dater.data[0].attributes.commercial &&
        renderarr.push(props.translation.data[0].attributes.FreeData1.ProjectDetailpage[17]);
    }

    return (
      <span className="ProjectsNewFlags">
        {renderarr.map((item, index) => {
          if (index < 5 && document.body.clientWidth > 1024) {
            return <p className="ProjectsNewFlag-flag">{item}</p>;
          }
          if (index < 3 && document.body.clientWidth < 1024) {
            return <p className="ProjectsNewFlag-flag">{item}</p>;
          }
        })}
      </span>
    );
  }

  useEffect(() => {
    if (props.newsheader) {
      if (props.dater.data.length > 0) {
        props.dater.data[0].attributes.BannerImage.data &&
          renderimage(props.dater.data[0].attributes.BannerImage.data.attributes.url);
      }
    }
    if (props.gelaenderCat) {
      props.dater.data[0].attributes.BannerImage.data &&
        renderimage(props.dater.data[0].attributes.BannerImage.data.attributes.url);

      if (props.dater.data[0].attributes.BannerTitle.length > 40) {
        // document.getElementById("HeaderMainpage-HeaderTitle").style="margin-top:160px"
      }
      if (props.dater.data[0].attributes.BannerTitle.length < 18) {
        //document.getElementById("HeaderMainpage-HeaderTitle").style="margin-top:286px"
      }
    }
    if (props.seotopic) {
      props.dater.data[0].attributes.BannerImage.data &&
        renderimage(props.dater.data[0].attributes.BannerImage.data.attributes.url);

      if (props.dater.data[0].attributes.BannerTitle.length > 40) {
        //document.getElementById("HeaderMainpage-HeaderTitle").style="margin-top:160px"
      }
      if (props.dater.data[0].attributes.BannerTitle.length < 18) {
        //document.getElementById("HeaderMainpage-HeaderTitle").style="margin-top:286px"
      }
    }
  }, [props.dater]);

  let linkkk = LinksShop.data[thefinalval];
  if (params.count == 'pl-pl' || params.count == 'pt-br' || params.count == 'pt-pt') {
    if (document.querySelector('#HeaderMainpage-Submenudiv-1')) {
      if (document.querySelector('.HeaderMainpage-ShopLinks')) {
        let lengthofarr = document.querySelectorAll('.HeaderMainpage-ShopLinks').length;
        if (params.count == 'pl-pl') {
          for (let i = 0; i < lengthofarr; i++) {
            document.querySelectorAll('.HeaderMainpage-ShopLinks')[i].style = 'font-size:22px !important';
          }
        }
        if (params.count != 'pl-pl') {
          for (let i = 0; i < lengthofarr; i++) {
            document.querySelectorAll('.HeaderMainpage-ShopLinks')[i].style = 'font-size:19px !important';
          }
        }

        const style = document.createElement('style');
        style.innerHTML = `
          
                    #responsivemenuitems .HeaderMainpage-ShopLinks{
                        font-size: 28px !important;
                        line-height:32px !important;
                        margin-top: 8px !important;
                        width: calc(100% - 20px) !important;
                    }
                    `;
        document.head.appendChild(style);
      }
    }
  }

  let usermenuger = ['Produkte', 'Konfigurator', 'Geländer', 'Inspiration', 'Service', 'Account erstellen'];
  let usermenuno = ['Produkter', 'Konfigurator', 'Rekkverk', 'Inspirasjon', 'Service', 'Opprett kont'];
  let usermenuda = ['Produkter', 'Konfigurator', 'Gelænder', 'Inspiration', 'Service', 'Opret konto'];
  let usermenunl = ['Producten', 'Configurator', 'Balustrades', 'Inspiratie', 'Service', 'Account aanmaken'];
  let usermenuengb = ['Products', 'Configurator', 'Balustrades', 'Inspiration', 'Service', 'Create Account'];
  let usermenuenus = ['Products', 'Configurator', 'Railings', 'Inspiration', 'Service', 'Create Account'];
  let usermenufi = ['Tuotteet', 'Konfiguraattori', 'Kaiteet', 'Inspiraatio', 'Palvelut', 'Luo tili'];
  let usermenufr = ['Produits', 'Configurateur', 'Garde-corps', 'Inspiration', 'Service', 'Créer un compte'];
  let usermenuit = ['Prodotti', 'Configuratore', 'Ringhiere', 'Ispirazione', 'Servizio', 'Crea un account'];
  let usermenupl = ['Produkty', 'Konfigurator', 'Balustrady', 'Inspiracje', 'Serwis', 'Utwórz konto'];
  let usermenupt = ['Produtos', 'Configurador', 'Guarda-corpos', 'Inspiração', 'Serviço', ' Criar conta'];
  let usermenues = ['Productos', 'Configurador', 'Barandillas', 'Inspiración', 'Servicio', 'Crear cuenta'];
  let usermenusv = ['Produkter', 'Konfigurator', 'Räcken', 'Inspiration', 'Service', 'Skapa konto'];
  let usermenutk = ['Ürünler', 'Yapılandırıcı', 'Korkuluk', 'İlham', 'Hizmetler', 'Hesap oluştur'];

  let webshopadjust = [
    'da-dk',
    'de-de',
    'de-at',
    'en-at',
    'en-ca',
    'en-gb',
    'en-ie',
    'en-us',
    'es-es',
    'es-us',
    'fi-fi',
    'fr-ca',
    'it-it',
    'nb-no',
    'nl-nl',
    'pl-pl',
    'sv-se',
  ];

  if (webshopadjust.includes(para.count)) {
    usermenuger[0] = 'Webshop';
    usermenuno[0] = 'Webshop';
    usermenuda[0] = 'Webshop';
    usermenunl[0] = 'Webshop';
    usermenuengb[0] = 'Webshop';
    usermenuenus[0] = 'Webshop';
    usermenufi[0] = 'Webshop';
    usermenufr[0] = 'Webshop';
    usermenuit[0] = 'Webshop';
    usermenupl[0] = 'Webshop';
    usermenupt[0] = 'Webshop';
    usermenues[0] = 'Webshop';
    usermenusv[0] = 'Webshop';
    usermenutk[0] = 'Webshop';
  }

  let usermenu = ['Products', 'Configurator', 'Balustrades', 'Inspiration', 'Service', 'Create Account'];

  useEffect(() => {
    let decider = params.count.split('-');
    if (decider[0] == 'de') {
      usermenu = usermenuger;
    }
    if (decider[0] == 'fr') {
      usermenu = usermenufr;
    }
    if (decider[0] == 'nb') {
      usermenu = usermenuno;
    }
    if (decider[0] == 'nl') {
      usermenu = usermenunl;
    }
    if (decider[0] == 'da') {
      usermenu = usermenuda;
    }
    if (decider[0] == 'en' && (decider[1] == 'us' || decider[1] == 'ca')) {
      usermenu = usermenuenus;
    }
    if (decider[0] == 'en' && decider[1] == 'gb') {
      usermenu = usermenuengb;
    }
    if (decider[0] == 'fi') {
      usermenu = usermenufi;
    }
    if (decider[0] == 'it') {
      usermenu = usermenuit;
    }
    if (decider[0] == 'pl') {
      usermenu = usermenupl;
    }
    if (decider[0] == 'pt') {
      usermenu = usermenupt;
    }
    if (decider[0] == 'es') {
      usermenu = usermenues;
    }
    if (decider[0] == 'sv') {
      usermenu = usermenusv;
    }
    if (decider[0] == 'tr') {
      usermenu = usermenutk;
    }
    setmenu22(usermenu);
  }, []);

  let decider = params.count.split('-');

  if (decider[0] == 'de') {
    usermenu = usermenuger;
  }
  if (decider[0] == 'fr') {
    usermenu = usermenufr;
  }
  if (decider[0] == 'nb') {
    usermenu = usermenuno;
  }
  if (decider[0] == 'nl') {
    usermenu = usermenunl;
  }
  if (decider[0] == 'da') {
    usermenu = usermenuda;
  }
  if (decider[0] == 'en' && (decider[1] == 'us' || decider[1] == 'ca')) {
    usermenu = usermenuenus;
  }
  if (decider[0] == 'en' && decider[1] == 'gb') {
    usermenu = usermenuengb;
  }
  if (decider[0] == 'fi') {
    usermenu = usermenufi;
  }
  if (decider[0] == 'it') {
    usermenu = usermenuit;
  }
  if (decider[0] == 'pl') {
    usermenu = usermenupl;
  }
  if (decider[0] == 'pt') {
    usermenu = usermenupt;
  }
  if (decider[0] == 'es') {
    usermenu = usermenues;
  }
  if (decider[0] == 'sv') {
    usermenu = usermenusv;
  }
  if (decider[0] == 'tr') {
    usermenu = usermenutk;
  }

  useEffect(() => {
    if (props.imgss) {
      document.getElementById('header-image-main').style = "background-image: url('" + props.imgss + "')";
    }
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/projects?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=' +
        params.projectname +
        '&populate=BannerImage',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setheaderdata2(json);
      });

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=Headerdata-mainpage',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setheaderdata(json);
      });
  }, []);

  useEffect(() => {
    if (headerdata2.data.length > 0) {
      if (headerdata2.data[0].attributes.BannerImage.data.attributes.url) {
        document.getElementById('header-image-main').style =
          "background-image: url('" + headerdata2.data[0].attributes.BannerImage.data.attributes.url + "')";
      }
    }
  }, [headerdata2]);

  function whiteheader(prop) {
    if (document.querySelector('.Header-wrapper')) {
      if (document.querySelector('.Header-wrapper').classList.contains('blackwhiteheader')) {
        document.querySelector('.Header-wrapper').classList.remove('blackwhiteheader');
        if (!prop) {
          document.getElementById('Q-railing-menu-shadow-1').style = ' display:none ';
        }
        setblackimg(false);
      }
    }
  }

  function blackheader(prop) {
    if (document.querySelector('.Header-wrapper')) {
      document.querySelector('.Header-wrapper').classList.add('blackwhiteheader');
      if (!prop) {
        document.getElementById('Q-railing-menu-shadow-1').style = ' display:block ';
      }
      setblackimg(true);
    }
  }

  function sufu() {
    if (sufuan || advancedsearchtoggle) {
      return (
        <div className="suggestmainpage">
          <motion.div initial={{ x: 0, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: 0, opacity: 0 }}>
            {advancedsearchtoggle && <SearchDropdownMeili searchtoggle={advancesearchfield}></SearchDropdownMeili>}
          </motion.div>
        </div>
      );
    }
  }

  function renderimage(path) {
    if (path && document.getElementById('header-image-main')) {
      document.getElementById('header-image-main').style = "background-image: url('" + path + "')";
    }
  }
  window.onscroll = function () {
    myscrollFunction();
  };
  const navbar = document.querySelector('.header-top-wrap');
  const sticky = 50;
  function myscrollFunction() {
    if (window.pageYOffset >= sticky && !advancedsearchtoggle) {
      navbar.classList.add('sticky');
      setstickery(true);
    } else {
      navbar.classList.remove('sticky');
      setstickery(false);
    }
  }
  function adjustheadline() {
    if (props.dater.data.length > 0) {
      if (props.dater.data[0].attributes.Title && !props.seotopic) {
        if (props.dater.data[0].attributes.Title.length > 40) {
          // document.getElementById("HeaderMainpage-HeaderTitle").style="margin-top:160px"
        }
        if (props.dater.data[0].attributes.Title.length < 18) {
          // document.getElementById("HeaderMainpage-HeaderTitle").style="margin-top:286px"
        }
      }
    }
  }

  function hovershananigans(nbr, index) {
    let imagetochange = document.querySelectorAll('.img-fake')[index];
    if (index == 0) {
      imagetochange.style =
        "background-image: url('https://strapiwmc4hstore.blob.core.windows.net/website-assets/configrailing0" +
        parseInt(nbr - 1) +
        ".webp')";
    }
    if (index == 1) {
      imagetochange.style =
        "background-image: url('https://strapiwmc4hstore.blob.core.windows.net/website-assets/railingg" +
        parseInt(nbr - 1) +
        ".webp')";
    } else {
      //imagetochange.style = "background-image: url('https://q-railing.s3.eu-central-1.amazonaws.com/images/railingg"+nbr+".jpg')"
    }

    let itemtochange;
    if (document.querySelectorAll('.Qrailingssubmenu-inner')[index].children[1].children[nbr - 1].children[0]) {
      itemtochange =
        document.querySelectorAll('.Qrailingssubmenu-inner')[index].children[1].children[nbr - 1].children[0];
    }
    if (index == 0) {
      for (let i = 0; i < 3; i++) {
        document.querySelectorAll('.Qrailingssubmenu-inner')[index].children[1].children[i].children[0].style =
          'color: #CCCFD2';
      }
    }

    if (index == 1) {
      for (let i = 0; i < 6; i++) {
        if (document.querySelectorAll('.Qrailingssubmenu-inner')[index].children[1].children[i].children[0]) {
          document.querySelectorAll('.Qrailingssubmenu-inner')[index].children[1].children[i].children[0].style =
            'color: #CCCFD2';
        }
      }
    }
    if (index == 2) {
      for (let i = 0; i < 5; i++) {
        if (document.querySelectorAll('.Qrailingssubmenu-inner')[index].children[1].children[i].children[0]) {
          document.querySelectorAll('.Qrailingssubmenu-inner')[index].children[1].children[i].children[0].style =
            'color: #CCCFD2';
        }
      }
    }
    itemtochange.style = 'color: #001020';
  }

  useEffect(() => {
    const adjustMarginTop = () => {
      let Adjustibus = document.querySelector('#HeaderMainpage-HeaderTitle');
      if (Adjustibus) {
        let nbr = Adjustibus.clientHeight;
        let all = 760;
        if (document.body.clientWidth < 1024) {
          all = 660;
        }

        let diff = all - nbr;

        document.querySelector('#HeaderMainpage-HeaderTitle').style = 'margin-top:' + diff / 2 + 'px !important';
      }
    };

    // Call the function initially
    adjustMarginTop();

    // Add the event listener for the resize event
    window.addEventListener('resize', adjustMarginTop);

    // Clean up function
    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener('resize', adjustMarginTop);
    };
  }, [props.dater]);

  function returnSubmenu(prop) {
    if (smallmenutrigger == '' || prop == 'no') {
      return <></>;
    }

    if (smallmenutrigger == 'ConfiMenu') {
      return (
        <>
          <li className="HeaderMainpage-SwoopMenu">
            <button id="HeaderMainpage-SwoopMenu-close" onClick={() => setsmallmenutrigger('')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0006 11.9999C21.0006 12.1988 20.9216 12.3896 20.7809 12.5303C20.6403 12.6709 20.4495 12.7499 20.2506 12.7499H5.5609L11.0312 18.2193C11.1009 18.289 11.1562 18.3717 11.1939 18.4628C11.2316 18.5538 11.251 18.6514 11.251 18.7499C11.251 18.8485 11.2316 18.9461 11.1939 19.0371C11.1562 19.1281 11.1009 19.2109 11.0312 19.2806C10.9615 19.3502 10.8788 19.4055 10.7878 19.4432C10.6967 19.4809 10.5991 19.5003 10.5006 19.5003C10.402 19.5003 10.3045 19.4809 10.2134 19.4432C10.1224 19.4055 10.0396 19.3502 9.96996 19.2806L3.21996 12.5306C3.15023 12.4609 3.09491 12.3782 3.05717 12.2871C3.01943 12.1961 3 12.0985 3 11.9999C3 11.9014 3.01943 11.8038 3.05717 11.7127C3.09491 11.6217 3.15023 11.539 3.21996 11.4693L9.96996 4.7193C10.1107 4.57857 10.3016 4.49951 10.5006 4.49951C10.6996 4.49951 10.8905 4.57857 11.0312 4.7193C11.1719 4.86003 11.251 5.05091 11.251 5.24993C11.251 5.44895 11.1719 5.63982 11.0312 5.78055L5.5609 11.2499H20.2506C20.4495 11.2499 20.6403 11.3289 20.7809 11.4696C20.9216 11.6103 21.0006 11.801 21.0006 11.9999Z"
                  fill="#001020"
                />
              </svg>
            </button>
            <div>
              <h4>
                {headerdata.data.length > 0
                  ? headerdata.data[0].attributes.PageTranslations.data[23]
                  : 'Configure your railing'}
              </h4>
            </div>
            <ul className="HeaderMainpage-Submenu">
              <li onMouseEnter={() => hovershananigans(1, 0)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?ea'}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[24]
                    : 'Baluster railing'}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(2, 0)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?egs'}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[25]
                    : 'Glass railing'}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(3, 0)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?ep'}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[26]
                    : 'Picket railing '}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(4, 0)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?bc'}>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[49] : 'Baseclamps '}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(5, 0)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?fb'}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[53]
                    : 'Juliet Balcony '}
                </Link>
              </li>
              {para.count != 'en-us' && para.count != 'es-us' && para.count != 'fr-ca' && para.count != 'en-ca' && (
                <li onMouseEnter={() => hovershananigans(6, 0)}>
                  <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?ql'}>
                    {headerdata.data.length > 0
                      ? headerdata.data[0].attributes.PageTranslations.data[54]
                      : 'Stainless steel posts '}
                  </Link>
                </li>
              )}
            </ul>

            <Link hrefLang={para.count} className="ResponsiveMenu-BottomLinks-2" to={'/' + para.count + linkarr[8]}>
              {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[7] : 'All Systems'}{' '}
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="" />
                <path
                  d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                  fill="#001020"
                />
              </svg>
            </Link>
            <Link hrefLang={para.count} className="ResponsiveMenu-BottomLinks-1" to={'/' + para.count + '/categories/'}>
              {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[48] : 'All Components'}{' '}
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="" />
                <path
                  d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                  fill="#001020"
                />
              </svg>
            </Link>
          </li>
        </>
      );
    }
    if (smallmenutrigger == 'railingMenu') {
      return (
        <>
          <li className="HeaderMainpage-SwoopMenu">
            <button id="HeaderMainpage-SwoopMenu-close" onClick={() => setsmallmenutrigger('')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0006 11.9999C21.0006 12.1988 20.9216 12.3896 20.7809 12.5303C20.6403 12.6709 20.4495 12.7499 20.2506 12.7499H5.5609L11.0312 18.2193C11.1009 18.289 11.1562 18.3717 11.1939 18.4628C11.2316 18.5538 11.251 18.6514 11.251 18.7499C11.251 18.8485 11.2316 18.9461 11.1939 19.0371C11.1562 19.1281 11.1009 19.2109 11.0312 19.2806C10.9615 19.3502 10.8788 19.4055 10.7878 19.4432C10.6967 19.4809 10.5991 19.5003 10.5006 19.5003C10.402 19.5003 10.3045 19.4809 10.2134 19.4432C10.1224 19.4055 10.0396 19.3502 9.96996 19.2806L3.21996 12.5306C3.15023 12.4609 3.09491 12.3782 3.05717 12.2871C3.01943 12.1961 3 12.0985 3 11.9999C3 11.9014 3.01943 11.8038 3.05717 11.7127C3.09491 11.6217 3.15023 11.539 3.21996 11.4693L9.96996 4.7193C10.1107 4.57857 10.3016 4.49951 10.5006 4.49951C10.6996 4.49951 10.8905 4.57857 11.0312 4.7193C11.1719 4.86003 11.251 5.05091 11.251 5.24993C11.251 5.44895 11.1719 5.63982 11.0312 5.78055L5.5609 11.2499H20.2506C20.4495 11.2499 20.6403 11.3289 20.7809 11.4696C20.9216 11.6103 21.0006 11.801 21.0006 11.9999Z"
                  fill="#001020"
                />
              </svg>
            </button>
            <div>
              <h4>
                {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[0] : 'OUR SYSTEMS'}
              </h4>
            </div>
            <ul className="HeaderMainpage-Submenu">
              <li>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[2]}>
                  {menu22[2]}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(1, 1)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[3]}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[1]
                    : 'Glass railing'}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(2, 1)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[4]}>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[2] : 'Stairs'}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(3, 1)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[5]}>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[3] : 'Handrails'}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(4, 1)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[6]}>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[4] : 'Balconies'}
                </Link>
              </li>
              <li onMouseEnter={() => hovershananigans(5, 1)}>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[7]}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[5]
                    : 'French balconies'}
                </Link>
              </li>

              <li onMouseEnter={() => hovershananigans(10, 1)}>
                <Link hrefLang={para.count} to={translateRoute('/balustrades/terrace-railings', para.count)}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[51]
                    : 'terrace-railing'}
                </Link>
              </li>

              {(para.count == 'en-gb' || para.count == 'en-ie') && (
                <li onMouseEnter={() => hovershananigans(9, 1)}>
                  <Link hrefLang={para.count} to={'/' + para.count + '/balustrades/fire-rated-glass-balustrades/'}>
                    Fire rated glass balustrades
                  </Link>
                </li>
              )}
            </ul>
            {props.gibberish && (
              <div className="HeaderMainpage-Submenu-Cards HeaderMainpage-Submenu-Cards2">
                <div className="HeaderMainpage-Submenu-Card">
                  <div className="HeaderMainpage-CardWrap">
                    <div className="HeaderMainpage-CardWrapInner">
                      <h3>
                        {headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[4][1]
                          : 'New Products'}
                      </h3>

                      <a
                        href={
                          headerdata.data.length > 0
                            ? headerdata.data[0].attributes.FreeData2.data[4][3]
                            : 'https://q-railing.com/'
                        }
                        className="HeaderMainpage-whatsNewLink"
                      >
                        {headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[4][2]
                          : 'New Products'}{' '}
                        ↗
                      </a>
                    </div>
                  </div>
                  <div>
                    <img
                      width={112}
                      height={112}
                      src={headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[4][0] : ''}
                    />
                  </div>
                </div>
                <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card2">
                  <div className="HeaderMainpage-CardWrap">
                    <div className="HeaderMainpage-CardWrapInner">
                      <h3>
                        {headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[5][1]
                          : 'New Products'}
                      </h3>

                      <a
                        href={
                          headerdata.data.length > 0
                            ? headerdata.data[0].attributes.FreeData2.data[5][3]
                            : 'https://q-railing.com/'
                        }
                        className="HeaderMainpage-whatsNewLink"
                      >
                        {headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[5][2]
                          : 'New Products'}{' '}
                        ↗
                      </a>
                    </div>
                  </div>
                  <div>
                    <img
                      width={112}
                      height={112}
                      src={headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[5][0] : ''}
                    />
                  </div>
                </div>
              </div>
            )}
            <Link hrefLang={para.count} className="ResponsiveMenu-BottomLinks-2" to={'/' + para.count + linkarr[8]}>
              {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[7] : 'All Systems'}{' '}
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="" />
                <path
                  d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                  fill="#001020"
                />
              </svg>
            </Link>
            <Link hrefLang={para.count} className="ResponsiveMenu-BottomLinks-1" to={'/' + para.count + '/categories/'}>
              {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[48] : 'All Components'}{' '}
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="" />
                <path
                  d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                  fill="#001020"
                />
              </svg>
            </Link>
          </li>
        </>
      );
    }
    if (smallmenutrigger == 'ServiceMenu') {
      return (
        <>
          <li className="HeaderMainpage-SwoopMenu">
            <button id="HeaderMainpage-SwoopMenu-close" onClick={() => setsmallmenutrigger('')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0006 11.9999C21.0006 12.1988 20.9216 12.3896 20.7809 12.5303C20.6403 12.6709 20.4495 12.7499 20.2506 12.7499H5.5609L11.0312 18.2193C11.1009 18.289 11.1562 18.3717 11.1939 18.4628C11.2316 18.5538 11.251 18.6514 11.251 18.7499C11.251 18.8485 11.2316 18.9461 11.1939 19.0371C11.1562 19.1281 11.1009 19.2109 11.0312 19.2806C10.9615 19.3502 10.8788 19.4055 10.7878 19.4432C10.6967 19.4809 10.5991 19.5003 10.5006 19.5003C10.402 19.5003 10.3045 19.4809 10.2134 19.4432C10.1224 19.4055 10.0396 19.3502 9.96996 19.2806L3.21996 12.5306C3.15023 12.4609 3.09491 12.3782 3.05717 12.2871C3.01943 12.1961 3 12.0985 3 11.9999C3 11.9014 3.01943 11.8038 3.05717 11.7127C3.09491 11.6217 3.15023 11.539 3.21996 11.4693L9.96996 4.7193C10.1107 4.57857 10.3016 4.49951 10.5006 4.49951C10.6996 4.49951 10.8905 4.57857 11.0312 4.7193C11.1719 4.86003 11.251 5.05091 11.251 5.24993C11.251 5.44895 11.1719 5.63982 11.0312 5.78055L5.5609 11.2499H20.2506C20.4495 11.2499 20.6403 11.3289 20.7809 11.4696C20.9216 11.6103 21.0006 11.801 21.0006 11.9999Z"
                  fill="#001020"
                />
              </svg>
            </button>

            <ul className="HeaderMainpage-Submenu">
              <div>
                <h4>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[28]
                    : 'Customer Service'}
                </h4>
              </div>
              <li>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[12]}>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[10] : 'Contact'}
                </Link>
              </li>
              <li>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[14]}>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[12] : 'Training'}
                </Link>
              </li>
              <li>
                <Link hrefLang={para.count} to={'/' + para.count + linkarr[16]}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[14]
                    : 'Cataloge and Brochures'}
                </Link>
              </li>
              <li>
                <Link hrefLang={para.count} to={'/' + para.count + '/service/prefix/'}>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[38] : 'Prefix'}
                </Link>
              </li>
              {para.count != 'pt-br' && para.count != 'fr-ch' && para.count != 'it-ch' && para.count != 'de-ch' && (
                <>
                  <li>
                    <a
                      hrefLang={para.count}
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.PageTranslations.data[42][1]
                          : '/Prefix/'
                      }
                    >
                      {headerdata.data.length > 0
                        ? headerdata.data[0].attributes.PageTranslations.data[42][0]
                        : 'Prefix'}
                    </a>
                  </li>
                  <li>
                    <a
                      hrefLang={para.count}
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.PageTranslations.data[39][1]
                          : '/Prefix/'
                      }
                    >
                      {headerdata.data.length > 0
                        ? headerdata.data[0].attributes.PageTranslations.data[39][0]
                        : 'Prefix'}
                    </a>
                  </li>
                  <li>
                    <a
                      hrefLang={para.count}
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.PageTranslations.data[40][1]
                          : '/Prefix/'
                      }
                    >
                      {headerdata.data.length > 0
                        ? headerdata.data[0].attributes.PageTranslations.data[40][0]
                        : 'Prefix'}
                    </a>
                  </li>
                  <li>
                    <a
                      hrefLang={para.count}
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.PageTranslations.data[41][1]
                          : '/Prefix/'
                      }
                    >
                      {headerdata.data.length > 0
                        ? headerdata.data[0].attributes.PageTranslations.data[41][0]
                        : 'Prefix'}
                    </a>
                  </li>
                </>
              )}
            </ul>

            <ul className="HeaderMainpage-Submenu">
              <div id="SupportResponsive-Header-line2">
                <h4>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[27]
                    : 'Product Support'}
                </h4>
              </div>
              <li>
                <Link
                  hrefLang={para.count}
                  className="responsiveMenuLink"
                  to={'/' + para.count + linkarr[13] + 'manual/'}
                >
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[32]
                    : 'Installation manuals'}
                </Link>
              </li>
              <li>
                <Link hrefLang={para.count} className="responsiveMenuLink" to={'/' + para.count + linkarr[13] + 'cad/'}>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[31]
                    : 'Technical drawings'}
                </Link>
              </li>
              <li>
                <Link
                  hrefLang={para.count}
                  className="responsiveMenuLink"
                  to={'/' + para.count + linkarr[13] + 'q-details/'}
                >
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[50] : 'Q-details'}
                </Link>
              </li>
              <li>
                <Link hrefLang={para.count} className="responsiveMenuLink" to={'/' + para.count + linkarr[13] + 'bim/'}>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[30] : 'BIM-files'}
                </Link>
              </li>
              <li>
                <Link
                  hrefLang={para.count}
                  className="responsiveMenuLink"
                  to={'/' + para.count + linkarr[13] + 'dops/'}
                >
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[37] : 'Dops'}
                </Link>
              </li>
              <li>
                <Link
                  hrefLang={para.count}
                  className="responsiveMenuLink"
                  to={'/' + para.count + linkarr[13] + 'certificates/'}
                >
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[34]
                    : 'Certificates'}
                </Link>
              </li>
              <li>
                <Link
                  hrefLang={para.count}
                  className="responsiveMenuLink"
                  to={'/' + para.count + linkarr[13] + 'datasheets/'}
                >
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[35] : 'Datasheets'}
                </Link>
              </li>
              <li>
                <Link
                  hrefLang={para.count}
                  className="responsiveMenuLink"
                  to={'/' + para.count + linkarr[13] + 'tendertext/'}
                >
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[29]
                    : 'Ausschreibungstext'}
                </Link>
              </li>
            </ul>

            <div className="HeaderMainpage-Submenu-Cards">
              <div className="HeaderMainpage-Service-headline" id="HeadlineMainpage-Service-headline3">
                <h4>
                  {headerdata.data.length > 0
                    ? headerdata.data[0].attributes.PageTranslations.data[44]
                    : 'Product Support'}
                </h4>
              </div>
              <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card-Service">
                <div className="HeaderMainpage-CardWrap">
                  <div className="HeaderMainpage-CardWrapInner">
                    <a
                      className="HeaderMainpage-whatsNewLink Dividerlink"
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[2][3]
                          : 'https://q-railing.com/'
                      }
                    >
                      <h3>
                        {headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[2][1]
                          : 'New Products'}
                      </h3>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill=""></rect>
                        <path
                          d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                          fill="#001020"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card2 HeaderMainpage-Submenu-Card-Service">
                <div className="HeaderMainpage-CardWrap">
                  <div className="HeaderMainpage-CardWrapInner">
                    <a
                      className="HeaderMainpage-whatsNewLink Dividerlink"
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[3][3]
                          : 'https://q-railing.com/'
                      }
                    >
                      <h3>
                        {headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[3][1]
                          : 'New Products'}
                      </h3>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill=""></rect>
                        <path
                          d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                          fill="#001020"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card2 HeaderMainpage-Submenu-Card-Service">
                <div className="HeaderMainpage-CardWrap">
                  <div className="HeaderMainpage-CardWrapInner">
                    <a
                      className="HeaderMainpage-whatsNewLink Dividerlink"
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[6][3]
                          : 'https://q-railing.com/'
                      }
                    >
                      <h3>
                        {headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[6][1]
                          : 'New Products'}
                      </h3>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill=""></rect>
                        <path
                          d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                          fill="#001020"
                        ></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </>
      );
    }
    if (smallmenutrigger == 'ShopMenu') {
      return (
        <>
          <li className="HeaderMainpage-SwoopMenu" id="Header-RespShopMenu">
            <button id="HeaderMainpage-SwoopMenu-close" onClick={() => setsmallmenutrigger('')}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0006 11.9999C21.0006 12.1988 20.9216 12.3896 20.7809 12.5303C20.6403 12.6709 20.4495 12.7499 20.2506 12.7499H5.5609L11.0312 18.2193C11.1009 18.289 11.1562 18.3717 11.1939 18.4628C11.2316 18.5538 11.251 18.6514 11.251 18.7499C11.251 18.8485 11.2316 18.9461 11.1939 19.0371C11.1562 19.1281 11.1009 19.2109 11.0312 19.2806C10.9615 19.3502 10.8788 19.4055 10.7878 19.4432C10.6967 19.4809 10.5991 19.5003 10.5006 19.5003C10.402 19.5003 10.3045 19.4809 10.2134 19.4432C10.1224 19.4055 10.0396 19.3502 9.96996 19.2806L3.21996 12.5306C3.15023 12.4609 3.09491 12.3782 3.05717 12.2871C3.01943 12.1961 3 12.0985 3 11.9999C3 11.9014 3.01943 11.8038 3.05717 11.7127C3.09491 11.6217 3.15023 11.539 3.21996 11.4693L9.96996 4.7193C10.1107 4.57857 10.3016 4.49951 10.5006 4.49951C10.6996 4.49951 10.8905 4.57857 11.0312 4.7193C11.1719 4.86003 11.251 5.05091 11.251 5.24993C11.251 5.44895 11.1719 5.63982 11.0312 5.78055L5.5609 11.2499H20.2506C20.4495 11.2499 20.6403 11.3289 20.7809 11.4696C20.9216 11.6103 21.0006 11.801 21.0006 11.9999Z"
                  fill="#001020"
                />
              </svg>
            </button>

            <ul className="HeaderMainpage-Submenu">
              <li className="HeaderMainpage-Submenuul-headline">
                <h4>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[46] : 'Systems'}
                </h4>
              </li>
              {headerdata.data.length > 0 &&
                headerdata.data[0].attributes.FreeData1.data[0].map((item, index) => {
                  return (
                    <>
                      <li key={'shopmenu' + index} className="HeaderMainpageShopLinks">
                        <div className="HeaderMainpageShoplinksVisiblePart">
                          <a hrefLang={para.count} className="HeaderMainpage-ShopLinks" href={item[1]}>
                            <span>{item[0]}</span>
                          </a>
                          <button
                            className="HeaderShopSubcategoryButton"
                            onClick={() => {
                              cardhovershananigans(item[2]);

                              if (HeaderSubmenuLevelSlug != item[2]) {
                                setHeaderSubmenuLevelSlug(item[2]);
                                document.querySelectorAll('.HeaderShopSubcategoryClass').forEach((item) => {
                                  if (item.classList.contains('HeaderShopSubcategoryShow')) {
                                    item.classList.remove('HeaderShopSubcategoryShow');
                                  }
                                });
                              }
                              if (
                                document
                                  .getElementById('HeaderShopSubcategory' + index)
                                  .classList.contains('HeaderShopSubcategoryShow')
                              ) {
                                /*document.querySelectorAll('.HeaderShopSubcategoryClass').forEach((item) => {
                                if (item.classList.contains('HeaderShopSubcategoryShow')) {
                                  item.classList.remove('HeaderShopSubcategoryShow');
                                }
                              });*/
                                document
                                  .getElementById('HeaderShopSubcategory' + index)
                                  .classList.remove('HeaderShopSubcategoryShow');

                                setHeaderSubmenuLevelSlug('');
                              } else {
                                document
                                  .getElementById('HeaderShopSubcategory' + index)
                                  .classList.add('HeaderShopSubcategoryShow');
                              }
                            }}
                          >
                            {' '}
                            <div id="HeaderMainpagePlusMinus">
                              {HeaderSubmenuLevelSlug != item[2] ? (
                                <img
                                  width="16px"
                                  height="16px"
                                  alt="plusIcon"
                                  src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/plusicon.png"
                                ></img>
                              ) : (
                                <img
                                  width="16"
                                  height="16"
                                  src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/minusicon.png"
                                ></img>
                              )}
                            </div>
                          </button>
                        </div>
                        <div id={'HeaderShopSubcategory' + index} className="HeaderShopSubcategoryClass">
                          {ProductCategoryArray.data.length > 0 &&
                            ProductCategoryArray.data.map((item, index) => {
                              if (index < 14) {
                                return (
                                  <a className="HeaderShopCard" href={item.attributes.Link}>
                                    <h4>{item.attributes.DisplayName}</h4>
                                  </a>
                                );
                              }
                            })}
                        </div>
                      </li>
                    </>
                  );
                })}
            </ul>

            <ul className="HeaderMainpage-Submenu">
              <li className="HeaderMainpage-Submenuul-headline">
                <h4>
                  {headerdata.data.length > 0 ? headerdata.data[0].attributes.PageTranslations.data[47] : 'Components'}
                </h4>
              </li>
              {headerdata.data.length > 0 &&
                headerdata.data[0].attributes.FreeData1.data[1].map((item, index) => {
                  return (
                    <>
                      <li key={'shopmenuitems' + index}>
                        <a hrefLang={para.count} className="HeaderMainpage-ShopLinks" href={item[1]}>
                          {item[0]}
                        </a>
                      </li>
                    </>
                  );
                })}
            </ul>

            <div className="HeaderMainpage-Submenu-Cards">
              <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card-Service">
                <div className="HeaderMainpage-CardWrap">
                  <div className="HeaderMainpage-CardWrapInner">
                    <h3>
                      {headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[0][1] : 'New Products'}
                    </h3>
                    <p className="HeaderMainpage-Card-text">
                      {headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[0][4] : ''}
                    </p>
                    <a
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[0][3]
                          : 'https://q-railing.com/'
                      }
                      className="HeaderMainpage-whatsNewLink"
                    >
                      {headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[0][2] : 'New Products'}
                    </a>
                  </div>
                </div>
                <div>
                  <img
                    width={112}
                    height={112}
                    src={headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[0][0] : ''}
                  />
                </div>
              </div>
              <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card2 HeaderMainpage-Submenu-Card-Service">
                <div className="HeaderMainpage-CardWrap">
                  <div className="HeaderMainpage-CardWrapInner">
                    <h3>
                      {headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[1][1] : 'New Products'}
                    </h3>
                    <p className="HeaderMainpage-Card-text">
                      {headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[1][4] : ''}
                    </p>
                    <a
                      href={
                        headerdata.data.length > 0
                          ? headerdata.data[0].attributes.FreeData2.data[1][3]
                          : 'https://q-railing.com/'
                      }
                      className="HeaderMainpage-whatsNewLink"
                    >
                      {headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[1][2] : 'New Products'}
                    </a>
                  </div>
                </div>
                <div>
                  <img
                    width={112}
                    height={112}
                    src={headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[1][0] : ''}
                  />
                </div>
              </div>
            </div>
          </li>
        </>
      );
    }
  }

  return (
    <>
      <div className="Header-wrapper" id="Q-railing-Mainpage-Header-Wrap">
        <div className="header-top-wrap">
          <div className="Stickcenter">
            <div className="Header-top" id="Header-top">
              <div className="Header-menu1">
                <ul id="mainmenu">
                  <li>
                    <Link hrefLang={para.count} to={'/' + params.count + '/'}>
                      <img
                        id="Q-railing-logo"
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-logo.png"
                        width="72"
                        height={'72'}
                        alt="Q-railing logo"
                      />
                    </Link>
                  </li>
                  <li
                    id="Qrailingshop"
                    onMouseLeave={() => whiteheader()}
                    onMouseEnter={() => {
                      blackheader();
                      // cardhovershananigans('FilterGlassrailBaseChannelsDisk');
                    }}
                  >
                    <div id="Q-railing-menu-shadow-animation-fix"></div>
                    <a id="QrailingshopLink" href={linkkk} target="_blank">
                      {menu22[0]}
                    </a>
                    <div id="QrailingssubmenuShop">
                      <div id="Q-railing-menu-shadow"></div>
                      <div className="Qrailingssubmenu-wrap ShopmenuHeaderWrap">
                        <div className="Qrailingssubmenu-inner  Qrailingssubmenu-innerService Qrailingssubmenu-innerShop">
                          <div className="HeaderMainpage-Submenudiv" id="HeaderMainpage-Submenudiv-1">
                            <ul className="HeaderMainpage-Submenuul">
                              {headerdata.data.length > 0 &&
                                headerdata.data[0].attributes.FreeData1.data[0].map((item, index) => {
                                  return (
                                    <>
                                      <li key={'shopmenumain' + index}>
                                        <a
                                          className="HeaderMainpage-ShopLinks"
                                          hrefLang={para.count}
                                          onMouseEnter={() => {
                                            cardhovershananigans(item[2]);
                                          }}
                                          href={item[1]}
                                        >
                                          {item[0]}
                                        </a>
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="HeaderMainpage-Submenudiv">
                            <h4 className="HeaderMainpage-Submenuul-headline">
                              {headerdata.data.length > 0
                                ? headerdata.data[0].attributes.PageTranslations.data[47]
                                : 'Components'}
                            </h4>
                            <ul className="HeaderMainpage-Submenuul">
                              {headerdata.data.length > 0 &&
                                headerdata.data[0].attributes.FreeData1.data[1].map((item, index) => {
                                  return (
                                    <>
                                      <li key={'shopmenusubmenu' + index}>
                                        <a
                                          hrefLang={para.count}
                                          target="_blank"
                                          className="HeaderMainpage-ShopLinks"
                                          href={item[1]}
                                        >
                                          {item[0]}
                                        </a>
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="Header-ShopCardGrid">
                            {ProductCategoryArray.data.length > 0 &&
                              ProductCategoryArray.data.map((item, index) => {
                                if (index < 14) {
                                  return (
                                    <a
                                      className="HeaderShopCard"
                                      href={item.attributes.Link}
                                      style={{ background: `url(${item.attributes.HeaderImage.data.attributes.url})` }}
                                    >
                                      <h4>{item.attributes.DisplayName}</h4>
                                    </a>
                                  );
                                }
                              })}
                          </div>
                          <div className="HeaderMainpage-Submenu-Cards" id="Header-menu-shopcards">
                            <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card-Service">
                              <div className="HeaderMainpage-CardWrap">
                                <div className="HeaderMainpage-CardWrapInner">
                                  <h3>
                                    {headerdata.data.length > 0
                                      ? headerdata.data[0].attributes.FreeData2.data[0][1]
                                      : 'New Products'}
                                  </h3>
                                  <p className="HeaderMainpage-Card-text">
                                    {headerdata.data.length > 0
                                      ? headerdata.data[0].attributes.FreeData2.data[0][4]
                                      : ''}
                                  </p>
                                  <a
                                    href={
                                      headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.FreeData2.data[0][3]
                                        : 'https://q-railing.com/'
                                    }
                                    className="HeaderMainpage-whatsNewLink"
                                  >
                                    {headerdata.data.length > 0
                                      ? headerdata.data[0].attributes.FreeData2.data[0][2]
                                      : 'New Products'}{' '}
                                  </a>
                                </div>
                              </div>
                              <div>
                                <img
                                  width={112}
                                  height={112}
                                  src={
                                    headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[0][0] : ''
                                  }
                                />
                              </div>
                            </div>
                            <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card2 HeaderMainpage-Submenu-Card-Service">
                              <div className="HeaderMainpage-CardWrap">
                                <div className="HeaderMainpage-CardWrapInner">
                                  <h3>
                                    {headerdata.data.length > 0
                                      ? headerdata.data[0].attributes.FreeData2.data[1][1]
                                      : 'New Products'}
                                  </h3>
                                  <p className="HeaderMainpage-Card-text">
                                    {headerdata.data.length > 0
                                      ? headerdata.data[0].attributes.FreeData2.data[1][4]
                                      : ''}
                                  </p>
                                  <a
                                    href={
                                      headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.FreeData2.data[1][3]
                                        : 'https://q-railing.com/'
                                    }
                                    className="HeaderMainpage-whatsNewLink"
                                  >
                                    {headerdata.data.length > 0
                                      ? headerdata.data[0].attributes.FreeData2.data[1][2]
                                      : 'New Products'}{' '}
                                  </a>
                                </div>
                              </div>
                              <div>
                                <img
                                  width={112}
                                  height={112}
                                  src={
                                    headerdata.data.length > 0 ? headerdata.data[0].attributes.FreeData2.data[1][0] : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="Submenu-buttons Submenu-buttons-Shop">
                            <div className="Submenu-buttons-inner Submenu-buttons-Shop-inner">
                              <div></div>
                              <div className="Submenu-link-side">
                                <Link hrefLang={para.count} to={'/' + para.count + linkarr[8]}>
                                  {headerdata.data.length > 0
                                    ? headerdata.data[0].attributes.PageTranslations.data[7]
                                    : 'All Systems'}{' '}
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect width="32" height="32" rx="16" fill="" />
                                    <path
                                      d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                      fill="#001020"
                                    />
                                  </svg>
                                </Link>

                                <Link
                                  id="AllShopCategoriesLink"
                                  hrefLang={para.count}
                                  to={'/' + para.count + '/categories/'}
                                >
                                  {headerdata.data.length > 0
                                    ? headerdata.data[0].attributes.PageTranslations.data[48]
                                    : 'All Components'}{' '}
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect width="32" height="32" rx="16" fill="" />
                                    <path
                                      d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                      fill="#001020"
                                    />
                                  </svg>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li id="Qrailingsconfigurator" onMouseLeave={() => whiteheader()} onMouseEnter={() => blackheader()}>
                    <Link id="QrailingsconfiguratorLink" hrefLang={para.count} to={'/' + params.count + linkarr[1]}>
                      {menu22[1]}
                    </Link>
                    <div id="Q-railing-menu-shadow-animation-fix"></div>
                    <div id="Qrailingssubmenu4">
                      <div id="Q-railing-menu-shadow"></div>
                      <div className="Qrailingssubmenu4-wrap">
                        <div className="Qrailingssubmenu-inner">
                          <div className="header-submenu-headline">
                            <h2>
                              {headerdata.data.length > 0
                                ? headerdata.data[0].attributes.PageTranslations.data[23]
                                : 'Configure your railing'}
                            </h2>
                          </div>
                          <ul className="HeaderMainpage-Submenuul">
                            <li onMouseEnter={() => hovershananigans(1, 0)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?ea'}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[24]
                                  : 'Baluster railing'}
                              </Link>
                            </li>
                            <li onMouseEnter={() => hovershananigans(2, 0)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?egs'}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[25]
                                  : 'Glass railing'}
                              </Link>
                            </li>
                            <li onMouseEnter={() => hovershananigans(3, 0)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?ep'}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[26]
                                  : 'Picket railing '}
                              </Link>
                            </li>
                            <li onMouseEnter={() => hovershananigans(4, 0)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?bc'}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[49]
                                  : 'Baseclamps '}
                              </Link>
                            </li>
                            <li onMouseEnter={() => hovershananigans(5, 0)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?fb'}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[53]
                                  : 'Juliet Balcony '}
                              </Link>
                            </li>
                            {para.count != 'en-us' &&
                              para.count != 'es-us' &&
                              para.count != 'fr-ca' &&
                              para.count != 'en-ca' && (
                                <li onMouseEnter={() => hovershananigans(6, 0)}>
                                  <Link hrefLang={para.count} to={'/' + para.count + linkarr[1] + '?ql'}>
                                    {headerdata.data.length > 0
                                      ? headerdata.data[0].attributes.PageTranslations.data[54]
                                      : 'Stainless steel posts '}
                                  </Link>
                                </li>
                              )}
                          </ul>
                          <div className="img-fake" id="img-fake-configurator"></div>
                          <div className="Submenu-buttons Submenu-buttons-Shop">
                            <div className="Submenu-buttons-inner Submenu-buttons-Shop-inner">
                              <div></div>
                              <div className="Submenu-link-side">
                                <Link hrefLang={para.count} to={'/' + para.count + linkarr[8]}>
                                  {headerdata.data.length > 0
                                    ? headerdata.data[0].attributes.PageTranslations.data[7]
                                    : 'All Systems'}{' '}
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect width="32" height="32" rx="16" fill="" />
                                    <path
                                      d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                      fill="#001020"
                                    />
                                  </svg>
                                </Link>

                                <Link
                                  id="AllShopCategoriesLink"
                                  hrefLang={para.count}
                                  to={'/' + para.count + '/categories/'}
                                >
                                  {headerdata.data.length > 0
                                    ? headerdata.data[0].attributes.PageTranslations.data[48]
                                    : 'All Components'}{' '}
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect width="32" height="32" rx="16" fill="" />
                                    <path
                                      d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                      fill="#001020"
                                    />
                                  </svg>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li id="Qrailings" onMouseLeave={() => whiteheader()} onMouseEnter={() => blackheader()}>
                    <Link id="QrailingsLink" hrefLang={para.count} to={'/' + params.count + linkarr[2]}>
                      {menu22[2]}
                    </Link>
                    <div id="Q-railing-menu-shadow-animation-fix"></div>
                    <div id="Qrailingssubmenu">
                      <div id="Q-railing-menu-shadow"></div>
                      <div className="Qrailingssubmenu-wrap">
                        <div className="Qrailingssubmenu-inner">
                          <div className="header-submenu-headline">
                            <h2>
                              {headerdata.data.length > 0
                                ? headerdata.data[0].attributes.PageTranslations.data[0]
                                : 'Our systems'}
                            </h2>
                          </div>
                          <ul className="HeaderMainpage-Submenuul">
                            <li onMouseEnter={() => hovershananigans(1, 1)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[3]}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[1]
                                  : 'Glass railing'}
                              </Link>
                            </li>
                            <li onMouseEnter={() => hovershananigans(2, 1)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[4]}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[2]
                                  : 'Stairs'}
                              </Link>
                            </li>
                            <li onMouseEnter={() => hovershananigans(3, 1)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[5]}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[3]
                                  : 'Handrails'}
                              </Link>
                            </li>
                            <li onMouseEnter={() => hovershananigans(4, 1)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[6]}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[4]
                                  : 'Balconies'}
                              </Link>
                            </li>
                            <li onMouseEnter={() => hovershananigans(5, 1)}>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[7]}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[5]
                                  : 'French balconies'}
                              </Link>
                            </li>

                            <li onMouseEnter={() => hovershananigans(10, 1)}>
                              <Link
                                hrefLang={para.count}
                                to={translateRoute('/balustrades/terrace-railings', para.count)}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[51]
                                  : 'terrace-railing'}
                              </Link>
                            </li>

                            {(para.count == 'en-gb' || para.count == 'en-ie') && (
                              <li onMouseEnter={() => hovershananigans(9, 1)}>
                                <Link
                                  hrefLang={para.count}
                                  to={'/' + para.count + '/balustrades/fire-rated-glass-balustrades/'}
                                >
                                  Fire rated glass balustrades
                                </Link>
                              </li>
                            )}
                          </ul>
                          <div className="img-fake"></div>
                          <div className="Submenu-buttons Submenu-buttons-Shop">
                            <div className="Submenu-buttons-inner Submenu-buttons-Shop-inner">
                              <div></div>
                              <div className="Submenu-link-side">
                                <Link hrefLang={para.count} to={'/' + para.count + linkarr[8]}>
                                  {headerdata.data.length > 0
                                    ? headerdata.data[0].attributes.PageTranslations.data[7]
                                    : 'All Systems'}{' '}
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect width="32" height="32" rx="16" fill="" />
                                    <path
                                      d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                      fill="#001020"
                                    />
                                  </svg>
                                </Link>

                                <Link
                                  id="AllShopCategoriesLink"
                                  hrefLang={para.count}
                                  to={'/' + para.count + '/categories/'}
                                >
                                  {headerdata.data.length > 0
                                    ? headerdata.data[0].attributes.PageTranslations.data[48]
                                    : 'All Components'}{' '}
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect width="32" height="32" rx="16" fill="" />
                                    <path
                                      d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                      fill="#001020"
                                    />
                                  </svg>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    id="QrailingInspiration"
                    onMouseLeave={() => whiteheader('insp')}
                    onMouseEnter={() => blackheader('insp')}
                  >
                    <Link id="QrailingInspirationLink" hrefLang={para.count} to={'/' + params.count + linkarr[10]}>
                      {menu22[3]}
                    </Link>
                  </li>
                  <li id="Qrailingservice" onMouseLeave={() => whiteheader()} onMouseEnter={() => blackheader()}>
                    <Link id="QrailingserviceLink" hrefLang={para.count} to={'/' + params.count + linkarr[11]}>
                      {menu22[4]}
                    </Link>
                    <div id="Q-railing-menu-shadow-animation-fix"></div>
                    <div id="Qrailingssubmenu2">
                      <div id="Q-railing-menu-shadow"></div>
                      <div className="Qrailingssubmenu-wrap Qrailingssubmenu-innerService-wrap">
                        <div className="Qrailingssubmenu-inner  Qrailingssubmenu-innerService">
                          <ul className="HeaderMainpage-Submenuul">
                            <div className="HeaderMainpage-Service-headline">
                              <h4>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[28]
                                  : 'Customer Service'}
                              </h4>
                            </div>
                            <li>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[12]}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[10]
                                  : 'Contact'}
                              </Link>
                            </li>
                            <li>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[14]}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[12]
                                  : 'Training'}
                              </Link>
                            </li>
                            <li>
                              <Link hrefLang={para.count} to={'/' + para.count + linkarr[16]}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[14]
                                  : 'Cataloge and Brochures'}
                              </Link>
                            </li>
                            <li>
                              <Link hrefLang={para.count} to={'/' + para.count + '/service/prefix/'}>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[38]
                                  : 'Prefix'}
                              </Link>
                            </li>
                            {para.count != 'pt-br' &&
                              para.count != 'fr-ch' &&
                              para.count != 'it-ch' &&
                              para.count != 'de-ch' && (
                                <>
                                  <li>
                                    <a
                                      hrefLang={para.count}
                                      href={
                                        headerdata.data.length > 0
                                          ? headerdata.data[0].attributes.PageTranslations.data[42][1]
                                          : '/Prefix/'
                                      }
                                    >
                                      {headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.PageTranslations.data[42][0]
                                        : 'Prefix'}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      hrefLang={para.count}
                                      href={
                                        headerdata.data.length > 0
                                          ? headerdata.data[0].attributes.PageTranslations.data[39][1]
                                          : '/Prefix/'
                                      }
                                    >
                                      {headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.PageTranslations.data[39][0]
                                        : 'Prefix'}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      hrefLang={para.count}
                                      href={
                                        headerdata.data.length > 0
                                          ? headerdata.data[0].attributes.PageTranslations.data[40][1]
                                          : '/Prefix/'
                                      }
                                    >
                                      {headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.PageTranslations.data[40][0]
                                        : 'Prefix'}
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      hrefLang={para.count}
                                      href={
                                        headerdata.data.length > 0
                                          ? headerdata.data[0].attributes.PageTranslations.data[41][1]
                                          : '/Prefix/'
                                      }
                                    >
                                      {headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.PageTranslations.data[41][0]
                                        : 'Prefix'}
                                    </a>
                                  </li>
                                </>
                              )}
                          </ul>
                          <ul className="HeaderMainpage-Submenuul">
                            <div className="HeaderMainpage-Service-headline" id="HeaderMainpage-ServiceHeadline2">
                              <h4>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[27]
                                  : 'Product Support'}
                              </h4>
                            </div>
                            <li>
                              <Link
                                hrefLang={para.count}
                                className="responsiveMenuLink"
                                to={'/' + para.count + linkarr[13] + 'manual/'}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[32]
                                  : 'Installation manuals'}
                              </Link>
                            </li>
                            <li>
                              <Link
                                hrefLang={para.count}
                                className="responsiveMenuLink"
                                to={'/' + para.count + linkarr[13] + 'cad/'}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[31]
                                  : 'Technical drawings'}
                              </Link>
                            </li>
                            <li>
                              <Link
                                hrefLang={para.count}
                                className="responsiveMenuLink"
                                to={'/' + para.count + linkarr[13] + 'q-details/'}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[50]
                                  : 'Q-details'}
                              </Link>
                            </li>
                            <li>
                              <Link
                                hrefLang={para.count}
                                className="responsiveMenuLink"
                                to={'/' + para.count + linkarr[13] + 'bim/'}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[30]
                                  : 'BIM-files'}
                              </Link>
                            </li>
                            <li>
                              <Link
                                hrefLang={para.count}
                                className="responsiveMenuLink"
                                to={'/' + para.count + linkarr[13] + 'dops/'}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[37]
                                  : 'Dops'}
                              </Link>
                            </li>
                            <li>
                              <Link
                                hrefLang={para.count}
                                className="responsiveMenuLink"
                                to={'/' + para.count + linkarr[13] + 'certificates/'}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[34]
                                  : 'Certificates'}
                              </Link>
                            </li>
                            <li>
                              <Link
                                hrefLang={para.count}
                                className="responsiveMenuLink"
                                to={'/' + para.count + linkarr[13] + 'datasheets/'}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[35]
                                  : 'Datasheets'}
                              </Link>
                            </li>
                            <li>
                              <Link
                                hrefLang={para.count}
                                className="responsiveMenuLink"
                                to={'/' + para.count + linkarr[13] + 'tendertext/'}
                              >
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[29]
                                  : 'Ausschreibungstext'}
                              </Link>
                            </li>
                          </ul>
                          <div className="HeaderMainpage-Submenu-Cards">
                            <div className="HeaderMainpage-Service-headline">
                              <h4>
                                {headerdata.data.length > 0
                                  ? headerdata.data[0].attributes.PageTranslations.data[44]
                                  : 'Product Support'}
                              </h4>
                            </div>
                            <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card-Service">
                              <div className="HeaderMainpage-CardWrap">
                                <div className="HeaderMainpage-CardWrapInner">
                                  <a
                                    className="HeaderMainpage-whatsNewLink Dividerlink"
                                    href={
                                      headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.FreeData2.data[2][3]
                                        : 'https://q-railing.com'
                                    }
                                  >
                                    <h3>
                                      {headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.FreeData2.data[2][1]
                                        : 'New Products'}
                                    </h3>
                                    <svg
                                      width="32"
                                      height="32"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect width="32" height="32" rx="16" fill=""></rect>
                                      <path
                                        d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                        fill="#001020"
                                      ></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card2 HeaderMainpage-Submenu-Card-Service">
                              <div className="HeaderMainpage-CardWrap">
                                <div className="HeaderMainpage-CardWrapInner">
                                  <a
                                    className="HeaderMainpage-whatsNewLink Dividerlink"
                                    href={
                                      headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.FreeData2.data[3][3]
                                        : 'https://q-railing.com'
                                    }
                                  >
                                    <h3>
                                      {headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.FreeData2.data[3][1]
                                        : 'New Products'}
                                    </h3>
                                    <svg
                                      width="32"
                                      height="32"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect width="32" height="32" rx="16" fill=""></rect>
                                      <path
                                        d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                        fill="#001020"
                                      ></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="HeaderMainpage-Submenu-Card HeaderMainpage-Submenu-Card2 HeaderMainpage-Submenu-Card-Service">
                              <div className="HeaderMainpage-CardWrap">
                                <div className="HeaderMainpage-CardWrapInner">
                                  <a
                                    className="HeaderMainpage-whatsNewLink Dividerlink"
                                    href={
                                      headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.FreeData2.data[6][3]
                                        : 'https://q-railing.com'
                                    }
                                  >
                                    <h3>
                                      {headerdata.data.length > 0
                                        ? headerdata.data[0].attributes.FreeData2.data[6][1]
                                        : 'New Products'}
                                    </h3>
                                    <svg
                                      width="32"
                                      height="32"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect width="32" height="32" rx="16" fill=""></rect>
                                      <path
                                        d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                                        fill="#001020"
                                      ></path>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul id="resp-menu">
                  <li>
                    <Link hrefLang={para.count} to={'/' + params.count + '/'}>
                      <img
                        id="responsive-logo"
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-logo.png"
                        width="72"
                        height={'72'}
                        alt="Responsive icon"
                      />
                    </Link>
                  </li>

                  <li>
                    {headerdata.data.length > 0 && (
                      <>
                        {headerdata.data[0].attributes.FreeData5 && (
                          <a href={headerdata.data[0].attributes.FreeData5.data[1]}>
                            <img
                              id="ChatLinkResponsive"
                              width={26}
                              height={26}
                              src={
                                stickery || blackimg
                                  ? 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/headset.png'
                                  : 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/headsetwhite.png'
                              }
                            />
                          </a>
                        )}
                      </>
                    )}
                    <a
                      hrefLang={para.count}
                      className="ResponsiveMenuHeaderLink"
                      id="ResponsiveSearchIcon"
                      onClick={() => {
                        advancedsearchtoggle ? setadvancedsearchtoggle(false) : setadvancedsearchtoggle(true);
                      }}
                    >
                      <img
                        alt="Link icon Search-responsive"
                        src={
                          stickery || blackimg
                            ? 'https://q-railing.s3.eu-central-1.amazonaws.com/icons/lupe_black.png'
                            : '' + lupe
                        }
                        width="26"
                        height="26"
                      />
                    </a>

                    <button
                      onClick={() => {
                        if (!responsivemenu) {
                          setresponsivemenu(true);
                          document.getElementById('resp-menu-icon').classList.add('rotation');
                        } else {
                          setresponsivemenu(false);
                          document.getElementById('resp-menu-icon').classList.remove('rotation');
                        }
                      }}
                    >
                      <img
                        alt="Burgermenu icon"
                        height={24}
                        id="resp-menu-icon"
                        width="24"
                        src={
                          responsivemenu
                            ? 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/xicon.png'
                            : stickery || blackimg
                              ? 'https://q-railing.s3.eu-central-1.amazonaws.com/icons/list_black.png'
                              : 'https://q-railing.s3.eu-central-1.amazonaws.com/icons/list.png'
                        }
                      />
                    </button>
                    {sufu()}
                  </li>
                </ul>
              </div>
              <div className="Header-menu2">
                <ul>
                  <li id="Header-Customer-Support">
                    {headerdata.data.length > 0 &&
                      para.count != 'pt-br' &&
                      para.count != 'pt-pt' &&
                      para.count != 'de-ch' &&
                      para.count != 'it-ch' &&
                      para.count != 'fr-ch' && (
                        <>
                          {headerdata.data[0].attributes.FreeData5 && (
                            <button
                              onClick={() => {
                                if (para.count != 'pt-br') {
                                  !showChat ? setShowChat(true) : setShowChat(false);
                                } else {
                                  window.location.replace(headerdata.data[0].attributes.FreeData5.data[1]);
                                }
                              }}
                            >
                              <img
                                id="ChatLogoHeader"
                                width={18}
                                height={18}
                                src={
                                  stickery || blackimg
                                    ? 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/headset.png'
                                    : 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/headsetwhite.png'
                                }
                              />{' '}
                              {headerdata.data[0].attributes.FreeData5.data[0]}
                            </button>
                          )}
                        </>
                      )}

                    {headerdata.data.length > 0 &&
                      (para.count == 'pt-br' ||
                        para.count == 'pt-pt' ||
                        para.count == 'de-ch' ||
                        para.count == 'it-ch' ||
                        para.count == 'fr-ch') && (
                        <>
                          {headerdata.data[0].attributes.FreeData5 && (
                            <a target="_blank" href={headerdata.data[0].attributes.FreeData5.data[1]}>
                              <img
                                id="ChatLogoHeader"
                                width={18}
                                height={18}
                                src={
                                  stickery || blackimg
                                    ? 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/headset.png'
                                    : 'https://strapiwmc4hstore.blob.core.windows.net/website-assets/headsetwhite.png'
                                }
                              />{' '}
                              {headerdata.data[0].attributes.FreeData5.data[0]}
                            </a>
                          )}
                        </>
                      )}
                  </li>

                  {para.count != 'pt-br' && para.count != 'fr-ch' && para.count != 'it-ch' && para.count != 'de-ch' && (
                    <li id="registerHeaderMenu">
                      <a hrefLang={param.count} href={'/' + params.count + '/register/'}>
                        {menu22[5]}
                      </a>
                    </li>
                  )}

                  <li id="searchmenu">
                    {sufu()}
                    <img
                      alt="searchmenu icon"
                      src={
                        stickery || blackimg
                          ? 'https://q-railing.s3.eu-central-1.amazonaws.com/icons/lupe_black.png'
                          : '' + lupe
                      }
                      width="26"
                      onClick={() => {
                        advancedsearchtoggle ? setadvancedsearchtoggle(false) : setadvancedsearchtoggle(true);
                      }}
                      height="26"
                    />
                  </li>
                </ul>
              </div>

              {responsivemenu && (
                <div id="responsivemenuitems">
                  {returnSubmenu()}
                  <li className={smallmenutrigger != '' && 'hidden'}>
                    <button
                      onClick={() => {
                        setsmallmenutrigger('ShopMenu');
                      }}
                      className="ResponsiveMenuHeaderLink-button"
                    >
                      {menu22[0]}
                    </button>
                    <button
                      className="ResponsiveMenu-PlusButton"
                      onClick={() => {
                        setsmallmenutrigger('ShopMenu');
                      }}
                    >
                      <img
                        width="16px"
                        height="16px"
                        alt="plusIcon"
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/plusicon.png"
                      />
                    </button>
                  </li>
                  <li className={smallmenutrigger != '' && 'hidden'}>
                    <button
                      onClick={() => {
                        setsmallmenutrigger('ConfiMenu');
                      }}
                      className="ResponsiveMenuHeaderLink-button"
                    >
                      {menu22[1]}
                    </button>
                    <button
                      className="ResponsiveMenu-PlusButton"
                      onClick={() => {
                        setsmallmenutrigger('ConfiMenu');
                      }}
                    >
                      <img
                        width="16px"
                        height="16px"
                        alt="plusIcon"
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/plusicon.png"
                      />
                    </button>
                  </li>
                  <li className={smallmenutrigger != '' && 'hidden'}>
                    <button
                      onClick={() => {
                        setsmallmenutrigger('railingMenu');
                      }}
                      className="ResponsiveMenuHeaderLink-button"
                    >
                      {menu22[2]}
                    </button>
                    <button
                      className="ResponsiveMenu-PlusButton"
                      onClick={() => {
                        setsmallmenutrigger('railingMenu');
                      }}
                    >
                      <img
                        width="16px"
                        height="16px"
                        alt="plusIcon"
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/plusicon.png"
                      />
                    </button>
                  </li>
                  <li id="ResponsiveInspirationMenu" className={smallmenutrigger != '' && 'hidden'}>
                    <Link
                      className="ResponsiveMenuHeaderLink"
                      hrefLang={para.count}
                      to={'/' + params.count + '/projects/'}
                    >
                      {menu22[3]}{' '}
                    </Link>
                    <Link
                      className="ResponsiveMenuHeaderLink"
                      hrefLang={para.count}
                      to={'/' + params.count + '/projects/'}
                    >
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="32" height="32" rx="16" fill="#FFFFFF" />
                        <path
                          d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                          fill="#001020"
                        />
                      </svg>
                    </Link>
                  </li>
                  <li className={smallmenutrigger != '' && 'hidden'}>
                    <button
                      className="ResponsiveMenuHeaderLink-button"
                      onClick={() => {
                        setsmallmenutrigger('ServiceMenu');
                      }}
                    >
                      {menu22[4]}
                    </button>
                    <button
                      className="ResponsiveMenu-PlusButton"
                      onClick={() => {
                        setsmallmenutrigger('ServiceMenu');
                      }}
                    >
                      <img
                        width="16px"
                        height="16px"
                        alt="plusIcon"
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/plusicon.png"
                      />
                    </button>
                  </li>

                  {para.count != 'pt-br' && para.count != 'fr-ch' && para.count != 'it-ch' && para.count != 'de-ch' && (
                    <li id="RegisterLinkResponsive" className={smallmenutrigger != '' && 'hidden'}>
                      <a
                        hrefLang={para.count}
                        className="ResponsiveMenuHeaderLink"
                        href={'/' + params.count + '/register/'}
                      >
                        {menu22[5]}{' '}
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M19.6484 17.8751C18.2206 15.4067 16.0203 13.6367 13.4525 12.7976C14.7226 12.0415 15.7094 10.8893 16.2614 9.51804C16.8134 8.14678 16.8999 6.63224 16.5078 5.20701C16.1157 3.78178 15.2666 2.52467 14.0909 1.62873C12.9151 0.732786 11.4778 0.247559 9.99964 0.247559C8.52146 0.247559 7.08414 0.732786 5.90842 1.62873C4.73269 2.52467 3.88358 3.78178 3.49146 5.20701C3.09935 6.63224 3.18592 8.14678 3.73788 9.51804C4.28984 10.8893 5.27668 12.0415 6.54683 12.7976C3.97902 13.6357 1.77871 15.4057 0.350893 17.8751C0.298533 17.9605 0.263803 18.0555 0.248752 18.1545C0.233701 18.2535 0.238634 18.3545 0.26326 18.4516C0.287886 18.5487 0.331707 18.6399 0.392136 18.7197C0.452565 18.7996 0.528379 18.8666 0.615104 18.9167C0.70183 18.9667 0.79771 18.999 0.897088 19.0114C0.996466 19.0238 1.09733 19.0163 1.19373 18.9891C1.29012 18.9619 1.3801 18.9157 1.45835 18.8532C1.5366 18.7907 1.60154 18.7131 1.64933 18.6251C3.41558 15.5726 6.53746 13.7501 9.99964 13.7501C13.4618 13.7501 16.5837 15.5726 18.35 18.6251C18.3977 18.7131 18.4627 18.7907 18.5409 18.8532C18.6192 18.9157 18.7092 18.9619 18.8056 18.9891C18.902 19.0163 19.0028 19.0238 19.1022 19.0114C19.2016 18.999 19.2975 18.9667 19.3842 18.9167C19.4709 18.8666 19.5467 18.7996 19.6072 18.7197C19.6676 18.6399 19.7114 18.5487 19.736 18.4516C19.7607 18.3545 19.7656 18.2535 19.7505 18.1545C19.7355 18.0555 19.7008 17.9605 19.6484 17.8751ZM4.74964 7.00011C4.74964 5.96176 5.05755 4.94672 5.63443 4.08337C6.21131 3.22001 7.03124 2.5471 7.99056 2.14974C8.94987 1.75238 10.0055 1.64841 11.0239 1.85099C12.0423 2.05356 12.9777 2.55357 13.712 3.2878C14.4462 4.02202 14.9462 4.95748 15.1488 5.97589C15.3513 6.99429 15.2474 8.04989 14.85 9.0092C14.4527 9.96851 13.7797 10.7884 12.9164 11.3653C12.053 11.9422 11.038 12.2501 9.99964 12.2501C8.60771 12.2486 7.27322 11.695 6.28898 10.7108C5.30473 9.72653 4.75113 8.39204 4.74964 7.00011Z"
                            fill="#001020"
                          />
                        </svg>
                      </a>
                    </li>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="header-banner">
          <div className="header-image" id="header-image-main">
            <div className="header-overlay"></div>
          </div>
          <div className="HeaderMainpage-text-wrap">
            <div className="HeaderMainpage-texts">
              <div className="header-text-left header-text-projects HeaderMainpage-text-left">
                <div id="HeaderMainpage-HeaderTitle" className="HeaderProjectsAdjust">
                  {props.dater.data.length > 0 && !props.systemstuff && (
                    <>
                      {props.dater.data[0].attributes.UsedSystem && props.bogus && (
                        <button
                          id="UsedSystem"
                          onClick={() => {
                            window.location.replace(
                              '/' + para.count + linkarr[8] + props.dater.data[0].attributes.UsedSystemSlug,
                            );
                          }}
                        >
                          {props.dater.data[0].attributes.UsedSystem}
                        </button>
                      )}
                      {props.dater.data[0].attributes.SecondarySystem && props.bogus && (
                        <button
                          id="Headerprojects-second-system"
                          onClick={() => {
                            window.location.replace(
                              '/' + para.count + linkarr[8] + props.dater.data[0].attributes.SecondarySystemSlug,
                            );
                          }}
                        >
                          {props.dater.data[0].attributes.SecondarySystem}
                        </button>
                      )}
                      {props.dater.data[0].attributes.Title && !props.dater.data[0].attributes.BannerTitle && (
                        <h1>{props.dater.data[0].attributes.Title}</h1>
                      )}
                      {props.dater.data[0].attributes.titel1 && <h1>{props.dater.data[0].attributes.titel1}</h1>}
                      {props.dater.data[0].attributes.BannerTitle && (
                        <h1
                          className="subheader-headernew"
                          dangerouslySetInnerHTML={{
                            __html: props.dater.data[0].attributes.BannerTitle,
                          }}
                        ></h1>
                      )}
                      {props.dater.data[0].attributes.BannerText && (
                        <p
                          className="subheader-headernew"
                          id={props.seotopic == 'safety' ? 'Headerprojects-safety-text' : ''}
                        >
                          {props.dater.data[0].attributes.BannerText}
                        </p>
                      )}
                      {props.dater.data[0].attributes.Description && (
                        <p className="subheader-headernew">{props.dater.data[0].attributes.Description}</p>
                      )}

                      {props.dater.data[0].attributes && renderprojectflags()}
                      {!props.seotopic && adjustheadline()}
                    </>
                  )}

                  {props.dater.data.length > 0 && props.systemstuff && (
                    <>
                      <div>
                        <h2>
                          {props.systemss && props.systemss.data[0].attributes.HeaderText.data[0][0]}{' '}
                          <h1>{props.systemss && props.systemss.data[0].attributes.HeaderText.data[0][1]}</h1>
                        </h2>
                        <h4>
                          {props.systemss && props.systemss.data[0].attributes.HeaderText.data[1][0]} <br />{' '}
                          {props.systemss && props.systemss.data[0].attributes.HeaderText.data[1][1]}
                        </h4>
                      </div>
                    </>
                  )}
                  <p></p>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      {headerdata.data.length > 0 &&
        para.count != 'pt-br' &&
        para.count != 'pt-pt' &&
        para.count != 'de-ch' &&
        para.count != 'it-ch' &&
        para.count != 'fr-ch' && (
          <>
            {headerdata.data[0].attributes.FreeData5 && (
              <>
                {showChat && (
                  <>
                    <button id="CloseChatBtnHeader" onClick={() => setShowChat(false)}>
                      <img
                        width={24}
                        height={24}
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/xiconweiss.png"
                      />
                    </button>
                    <div id="ChatIFrameLoading">
                      <img
                        src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-logo.png"
                        alt="RotatingLogo"
                        width="72"
                        height={'72'}
                      />
                    </div>
                    <iframe
                      src={headerdata.data[0].attributes.FreeData5.data[1]}
                      style={{
                        width: '338px',
                        height: '520px',
                        position: 'fixed',
                        bottom: 0,
                        right: 0,
                        zIndex: 1000,
                        border: 'none',
                      }}
                      title="Chat"
                      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                      onLoad={() => {
                        if (document.getElementById('CloseChatBtnHeader')) {
                          document.getElementById('CloseChatBtnHeader').style = 'display:block !important';
                        }
                      }}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      <div id="Q-railing-menu-shadow-1"></div>
    </>
  );
}

export default Headerprojects;
